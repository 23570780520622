<template>
  <router-view v-slot="{ Component, route }">
    <transition name="slide-fade" mode="out-in">
      <div :key="route.name">
        <component :is="Component" />
      </div>
    </transition>
  </router-view>
</template>

<script setup>
import { onMounted } from "vue"
import "@/assets/styles/main.scss"
import "@/assets/styles/keyframes.css"
import { heightVH } from "@/helpers"

onMounted(() => {
  heightVH()
})
</script>

<style lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease-out;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateY(-50px);
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(0);
}
</style>
