export const useSwipeMenu = (menu, closeFunc) => {
  let startY = 0
  let threshold = 20 // Пороговое значение для определения смахивания вниз
  // без проверки на null может сработать ошибка при быстром переходе со страницы на страницу
  menu.value?.addEventListener("touchstart", function (event) {
    startY = event.touches[0].clientY
  })
  menu.value?.addEventListener("touchmove", function (event) {
    event.preventDefault()
    let currentY = event.touches[0].clientY
    let distance = currentY - startY

    if (distance > threshold) {
      closeFunc()
    }
  })
}
