export const useMouseScroll = (wrapper) => {
  let isDown = false
  let startX
  let scrollLeft

  wrapper.forEach((item) => {
    item.addEventListener("mousedown", (e) => {
      item.style.scrollSnapType = "none"
      isDown = true
      startX = e.pageX - item.offsetLeft
      scrollLeft = item.scrollLeft
    })
    item.addEventListener("mouseleave", () => {
      isDown = false
    })
    item.addEventListener("mouseup", () => {
      item.style.scrollSnapType = "x mandatory"
      isDown = false
    })
    item.addEventListener("mousemove", (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - item.offsetLeft
      const walk = (x - startX) * 3
      item.scrollLeft = scrollLeft - walk
    })
  })
}
