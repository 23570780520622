export const getTitle = (routeName) => {
  return {
    Home: "ПСБ цифровая лаборатория",
    News: "Новости",
    Events: "Митап",
    Reports: "Доклад",
    Frontend: "Вакансия Frontend-разработчик",
    Java: "Вакансия Java-разработчик",
    Analytic: "Вакансия Системный аналитик",
    Net: "Вакансия Net",
    Qa: "Вакансия Старший инженер по тестированию",
    Opensource: "Вакансия Администратор OpenSource",
    Postgre: "Вакансия Администратор PostgreSQL",
    Devops: "Вакансия Devops",
    EngineerQA: "Вакансия Инженер по нагрузочному тестированию",
    Sqa: "День докладов",
    Prod: "Подкаст ПСБ",
    Media: "Мы в СМИ",
    Substitution: "Импортозамещение",
    Students: "Первые митапы для студентов",
    Yaroslavl: "Митап в Ярославле",
    Yar: "Пришлашаем на митап ПСБ в Ярославле",
    School: "ПСБ запустил онлайн-школу",
    Sevastopol: "Митап в Севастополе",
    Analyst: "Конференция Analyst Days",
    'Devops-novyy-podkhod-v-razrabotke': "DevOps — новый подход в разработке",
    Intelligence: "Искусственный интеллект",
    Days: "Analyst-days/18",
    "Js-conference": "Конференция Сибирь.JS",
    "Mono-app": "приложение «Мой Бизнес»",
    "It-event": "ИТ-событие Северо-запада",
    "What-agile-fault": "В чем виноват Agile?",
    "Artyom-domashev-visiting-bagreport": "Артём Домашев в гостях у Багрепорта",
    "Psb-na-forume-inzhenery-budushchego":
      "ПСБ на форуме «Инженеры будущего – 2024»",
    "Kak-modulnoye-testirovaniye-sokrashchayet-zatraty-programmista":
      "Как модульное тестирование сокращает затраты программиста",
    NotFound: "Страница не найдена",
  }[routeName]
}
