import { callBeforeEnter } from "./beforeEnter"

const allRoutes = []

const modifyName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

const vacancies = [
  "frontend",
  "java",
  "analytic",
  "qa",
  "net",
  "opensource",
  "postgre",
  "devops",
  "engineerQA",
]

vacancies.forEach((category) => {
  let name = modifyName(category)

  allRoutes.push({
    path: `/vacancy/:id`,
    props: true,
    name: `${name}`,
    component: () => import(/*webpackChunkName:"Vacancy"*/ "@/views/Vacancy/HiringVacancy.vue"),
    beforeEnter(to, _, next) {
      callBeforeEnter("vacancies", to, next)
    },
  })
})

const news = [
  "sqa",
  "prod",
  "media",
  "substitution",
  "students",
  "yaroslavl",
  "school",
  "sevastopol",
  "js-conference",
  "mono-app",
  "intelligence",
  "days",
  "it-event",
  "what-agile-fault",
  "artyom-domashev-visiting-bagreport",
  "psb-na-forume-inzhenery-budushchego",
  "kak-modulnoye-testirovaniye-sokrashchayet-zatraty-programmista",
]

news.forEach((title) => {
  let name = modifyName(title)

  allRoutes.push({
    path: "/report/:id",
    props: true,
    name: `${name}`,
    component: () => import(/*webpackChunkName:"ReportsPage"*/ "@/views/Reports/ReportsPage.vue"),
    beforeEnter(to, _, next) {
      callBeforeEnter("reports", to, next)
    },
  })
})

const events = ["devops-novyy-podkhod-v-razrabotke"]

events.forEach((title) => {
  let name = modifyName(title)

  allRoutes.push({
    path: "/event/:id",
    props: true,
    name: `${name}`,
    component: () => import(/*webpackChunkName:"EventsPage"*/ "@/views/Events/EventsPage.vue"),
    beforeEnter(to, _, next) {
      callBeforeEnter("events", to, next)
    },
  })
})

const meetup = ["yar"]

meetup.forEach((title) => {
  let name = modifyName(title)

  allRoutes.push({
    path: "/meetup/:id",
    props: true,
    name: `${name}`,
    component: () => import(/*webpackChunkName:"EventsMeetup"*/ "@/views/Events/EventsMeetup.vue"),
    beforeEnter(to, _, next) {
      callBeforeEnter("meetups", to, next)
    },
  })
})

export default allRoutes
